@import 'node_modules/react-modal-video/scss/modal-video.scss';

$light-bg: #f5fcff;

.theme-light-bg {
    background-color: $light-bg;
}

.custom-card-1 {
    min-height: 230px;
    border-radius: 30px;
    box-shadow: 0 2px 20px 0 rgb(0 0 0/6%);
    border: 1px solid #909090;
    background-color: #fff;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.test-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &>div {
        &:last-child {
            column-gap: .5rem;
            display: flex;
            align-items: center;
        }
    }
}

.ourlabs-text {
    p {
        font-weight: 600;
    }
    ul {
        margin-top: 1rem;
        list-style: disc;
        padding-left: 2rem;
        li{
            margin-bottom: .5rem;
        }
    }
}
.blog-detail{
    ul{
        margin-top: 1rem;
        list-style: disc;
        padding-left: 2rem;
    }
}
.main-menu {
    justify-content: center;
    gap: 1rem;
}
.topLogo1{
    position: absolute;
    top: 0.5rem;
    img{
        width: 120px;
    }
}
.slick-slider.slick-initialized > button {
    display: none !important;
}

.sideBarLogo {
    padding: 0 1rem;
    img {
        width: 120px;
        padding: 0.3rem 0;
    }

}
.cart-container{
width: 70%;
min-height: 100px;
box-shadow: 0 0 8px -5px #878787;
border-radius: 0.5rem;
border: 1px solid #e7e7e7;
margin: 0 auto;
padding: 2rem;
}

.table {
    border-collapse: collapse;
    border-style: hidden;
    width: 100%;
    display: table;
    th {
        text-align: start;
        font-weight: 500;
        font-size: 1rem;
    }
    th, td{
        background-color: #fff;
        border: 1px solid #e2eeff;
        color: #001747;
        font-size: .875rem;
        font-weight: 400;
        padding: 1.5rem 0;
        &:not(:first-child){
            padding-left: 1rem;
        }
    }
}


.otpInput-container{
    input{
        border: 1px solid #eee;
    }
}

.googleReviews{
    position: relative;
    z-index: 99;
    a {
    border-radius: 10px 0 0 10px;
    padding: 0.5rem 1rem;
    position: fixed;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    right: 0;
    top: 100px;
    font-size: 1.5rem;
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 4px 0 #a1a1a1;
    img{
        width: 40px;
    }
}}
.subHeader{
    background-image: url(https://mrkaiweb.com/drpath_admin/public/uploads/banner/dr_path_careed7b8d30236f65619d8d80b92b811cdd.jpg);   
    background-size: cover;
    background-position: center;
    position: relative;
    &::before{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        background-color: #0c0c0c57;
    }
    &>div{
        z-index: 1;
    }
}
.b-detail{
    h4{
        font-size: 1.25rem;
        font-weight: 800;
    }
    .table-custom{
        table{
            width: 100%;
            border: 1px solid #ccc;
            border-collapse: collapse;
            th,td{
                padding: .5rem;
                border: 1px solid #ccc;
                text-align: start;
            }
        }
    }
    .more-detail{
        margin-top: 1.5rem;
        h5{
            font-size: 1.1rem;
            font-weight: 600;
            border-bottom: 1px solid #ccc;
            padding: 0 0 .5rem 0;
        }
        div{
            margin-top: .5rem;
            hr{
                margin: .5rem 0;
            }
        }
    }

}

.call_us_icon {
    border-radius: 15px;
    position: fixed;
    right: 15px;
    bottom: 7rem;
    z-index: 999;
    background: #0ea5e9;
    padding: 10px;
    transition: 0.45s;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 30%);
}


.whatsapp a {
    padding: 10px;
}


.whatsapp {
    border-radius: 15px;
    position: fixed;
    right: 15px;
    bottom: 1rem;
    z-index: 999;
    background: #fff;
    transition: 0.45s;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 30%);
}
.loading-page{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}
$small: 300px;
$medium: 1400px;
.card-footer{
    margin-top: .5rem;
}
@media screen and (min-width: $small) and (max-width: $medium) {
    .common-btn{
        font-weight: 600 !important;
        font-size: .75rem !important;
    }
}



.cst-text{
    h3, h2{
        font-size: 1.5rem;
        color: #000;
        font-weight: 700;
    }
    a{
        color: #0ea5e9;
    }
    ol, ul {
        list-style: auto;
        padding-left: 1rem;
    }
}

.otpInput-container > div {
    gap: 1rem;
    & > * {
        margin: 0 !important;
    }
}

